@import 'src/styles/_vars.scss';
@import '../../styles/mixins';
.App {
  background: $main-background;
  min-height: 100vh;
  padding-top: $header-height;

  #main-content {
    min-height: calc(100vh - #{$header-height + $footer-height});
    position: relative;
    z-index: 1;
  }
}
button,
input {
  border: none;
  outline: none;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-select-single .ant-select-selector .ant-select-selection-item {
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
  border-radius: inherit !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  height: 100% !important;
  width: 100% !important;
  gap: 8px !important;
  background-color: transparent !important;
}

.ant-modal-content {
  padding: 0 !important;
  border-radius: 12px !important;
}
.ant-modal-body {
  padding: 0 !important;
  border-radius: 12px !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $mainColor !important;
  border-color: $mainColor !important;
  border-radius: 5px !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.ant-checkbox + span {
  color: $text-black-color;
  font-size: 16px;
  font-weight: 500;
  &::first-letter {
    text-transform: capitalize;
  }
  @include respond-to('tablet') {
    font-size: 14px;
  }
  @include respond-to('mobile') {
    font-size: 12px;
  }
}
