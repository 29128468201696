@import './functions';

$breakpoints: (
  'mobile-xs': (
    max-width: 350px
  ),
  'mobile': (
    max-width: 539px
  ),
  'tablet': (
    max-width: 768px
  ),
  'laptop-small': (
    max-width: 800px
  ),
  'laptop': (
    max-width: 1024px
  ),
  'laptop-l': (
    max-width: 1152px
  ),
  'desktop': (
    max-width: 1280px
  ),
  'desktop-l': (
    max-width: 1360px
  ),
  'desktop-large': (
    max-width: 1440px
  ),
  'desktop-xl': (
    max-width: 1600px
  )
) !default;

@mixin respond-to($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media #{inspect(map-get($breakpoints, $breakpoint))} {
      @content;
    }
  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
          + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

@mixin zoom-out($zoom) {
  zoom: $zoom;
  @media all and (min-width: 1600px) {
    zoom: 1;
  }
  @media all and (max-width: 768px) {
    zoom: 1;
  }
}

@mixin row-gap($amount) {
  gap: $amount;
  & > * + * {
    margin-left: 0;
    margin-top: 0;
  }
  @include respond-to('tablet') {
    gap: 0;
    & > * + * {
      margin-left: $amount;
      margin-top: 0;
    }
  }
}

@mixin column-gap($amount) {
  gap: $amount;
  & > * + * {
    margin-top: 0;
    margin-left: 0;
  }
  @include respond-to('tablet') {
    gap: 0;
    & > * + * {
      margin-top: $amount;
      margin-left: 0;
    }
  }
}

@mixin prop-width($original, $viewport: 1920) {
  @include respond-to('desktop-xl') {
    width: propValue(1600, $original, $viewport);
  }
  @include respond-to('desktop-large') {
    width: propValue(1440, $original, $viewport);
  }
  @include respond-to('desktop-l') {
    width: propValue(1360, $original, $viewport);
  }
  @include respond-to('desktop') {
    width: propValue(1280, $original, $viewport);
  }
  @include respond-to('laptop-l') {
    width: propValue(1152, $original, $viewport);
  }
  @include respond-to('laptop') {
    width: propValue(1024, $original, $viewport);
  }
  @include respond-to('laptop-small') {
    width: propValue(800, $original, $viewport);
  }
}

@mixin prop-height($original, $viewport: 1920) {
  @include respond-to('desktop-xl') {
    height: propValue(1600, $original, $viewport);
  }
  @include respond-to('desktop-large') {
    height: propValue(1440, $original, $viewport);
  }
  @include respond-to('desktop-l') {
    height: propValue(1360, $original, $viewport);
  }
  @include respond-to('desktop') {
    height: propValue(1280, $original, $viewport);
  }
  @include respond-to('laptop-l') {
    height: propValue(1152, $original, $viewport);
  }
  @include respond-to('laptop') {
    height: propValue(1024, $original, $viewport);
  }
  @include respond-to('laptop-small') {
    height: propValue(800, $original, $viewport);
  }
}

@mixin prop-fontSize($original, $viewport: 1920) {
  @include respond-to('desktop-xl') {
    font-size: propValue(1600, $original, $viewport);
  }
  @include respond-to('desktop-large') {
    font-size: propValue(1440, $original, $viewport);
  }
  @include respond-to('desktop-l') {
    font-size: propValue(1360, $original, $viewport);
  }
  @include respond-to('desktop') {
    font-size: propValue(1280, $original, $viewport);
  }
  @include respond-to('laptop-l') {
    font-size: propValue(1152, $original, $viewport);
  }
  @include respond-to('laptop') {
    font-size: propValue(1024, $original, $viewport);
  }
  @include respond-to('laptop-small') {
    font-size: propValue(800, $original, $viewport);
  }
}

@mixin prop-padding($value, $viewport: 1920) {
  @include respond-to('desktop-xl') {
    padding: propValue(1600, $value, $viewport);
  }
  @include respond-to('desktop-large') {
    padding: propValue(1440, $value, $viewport);
  }
  @include respond-to('desktop-l') {
    padding: propValue(1360, $value, $viewport);
  }
  @include respond-to('desktop') {
    padding: propValue(1280, $value, $viewport);
  }
  @include respond-to('laptop-l') {
    padding: propValue(1152, $value, $viewport);
  }
  @include respond-to('laptop') {
    padding: propValue(1024, $value, $viewport);
  }
  @include respond-to('laptop-small') {
    padding: propValue(800, $value, $viewport);
  }
}

@mixin prop-padding-top($value, $viewport: 1920) {
  @include respond-to('desktop-xl') {
    padding-top: propValue(1600, $value, $viewport);
  }
  @include respond-to('desktop-large') {
    padding-top: propValue(1440, $value, $viewport);
  }
  @include respond-to('desktop-l') {
    padding-top: propValue(1360, $value, $viewport);
  }
  @include respond-to('desktop') {
    padding-top: propValue(1280, $value, $viewport);
  }
  @include respond-to('laptop-l') {
    padding-top: propValue(1152, $value, $viewport);
  }
  @include respond-to('laptop') {
    padding-top: propValue(1024, $value, $viewport);
  }
  @include respond-to('laptop-small') {
    padding-top: propValue(800, $value, $viewport);
  }
}

@mixin prop-padding-right($value, $viewport: 1920) {
  @include respond-to('desktop-xl') {
    padding-right: propValue(1600, $value, $viewport);
  }
  @include respond-to('desktop-large') {
    padding-right: propValue(1440, $value, $viewport);
  }
  @include respond-to('desktop-l') {
    padding-right: propValue(1360, $value, $viewport);
  }
  @include respond-to('desktop') {
    padding-right: propValue(1280, $value, $viewport);
  }
  @include respond-to('laptop-l') {
    padding-right: propValue(1152, $value, $viewport);
  }
  @include respond-to('laptop') {
    padding-right: propValue(1024, $value, $viewport);
  }
  @include respond-to('laptop-small') {
    padding-right: propValue(800, $value, $viewport);
  }
}

@mixin prop-padding-bottom($value, $viewport: 1920) {
  @include respond-to('desktop-xl') {
    padding-bottom: propValue(1600, $value, $viewport);
  }
  @include respond-to('desktop-large') {
    padding-bottom: propValue(1440, $value, $viewport);
  }
  @include respond-to('desktop-l') {
    padding-bottom: propValue(1360, $value, $viewport);
  }
  @include respond-to('desktop') {
    padding-bottom: propValue(1280, $value, $viewport);
  }
  @include respond-to('laptop-l') {
    padding-bottom: propValue(1152, $value, $viewport);
  }
  @include respond-to('laptop') {
    padding-bottom: propValue(1024, $value, $viewport);
  }
  @include respond-to('laptop-small') {
    padding-bottom: propValue(800, $value, $viewport);
  }
}

@mixin prop-padding-left($value, $viewport: 1920) {
  @include respond-to('desktop-xl') {
    padding-left: propValue(1600, $value, $viewport);
  }
  @include respond-to('desktop-large') {
    padding-left: propValue(1440, $value, $viewport);
  }
  @include respond-to('desktop-l') {
    padding-left: propValue(1360, $value, $viewport);
  }
  @include respond-to('desktop') {
    padding-left: propValue(1280, $value, $viewport);
  }
  @include respond-to('laptop-l') {
    padding-left: propValue(1152, $value, $viewport);
  }
  @include respond-to('laptop') {
    padding-left: propValue(1024, $value, $viewport);
  }
  @include respond-to('laptop-small') {
    padding-left: propValue(800, $value, $viewport);
  }
}

@mixin prop-padding-inline($left, $right, $viewport: 1920) {
  @include respond-to('desktop-xl') {
    padding-left: propValue(1600, $left, $viewport);
    padding-right: propValue(1600, $right, $viewport);
  }
  @include respond-to('desktop-large') {
    padding-left: propValue(1440, $left, $viewport);
    padding-right: propValue(1440, $right, $viewport);
  }
  @include respond-to('desktop-l') {
    padding-left: propValue(1360, $left, $viewport);
    padding-right: propValue(1360, $right, $viewport);
  }
  @include respond-to('desktop') {
    padding-left: propValue(1280, $left, $viewport);
    padding-right: propValue(1280, $right, $viewport);
  }
  @include respond-to('laptop-l') {
    padding-left: propValue(1152, $left, $viewport);
    padding-right: propValue(1152, $right, $viewport);
  }
  @include respond-to('laptop') {
    padding-left: propValue(1024, $left, $viewport);
    padding-right: propValue(1024, $right, $viewport);
  }
  @include respond-to('laptop-small') {
    padding-left: propValue(800, $left, $viewport);
    padding-right: propValue(800, $right, $viewport);
  }
}

@mixin prop-padding-vertical($top, $bottom, $viewport: 1920) {
  @include respond-to('desktop-xl') {
    padding-top: propValue(1600, $top, $viewport);
    padding-bottom: propValue(1600, $bottom, $viewport);
  }
  @include respond-to('desktop-large') {
    padding-top: propValue(1440, $top, $viewport);
    padding-bottom: propValue(1440, $bottom, $viewport);
  }
  @include respond-to('desktop-l') {
    padding-top: propValue(1360, $top, $viewport);
    padding-bottom: propValue(1360, $bottom, $viewport);
  }
  @include respond-to('desktop') {
    padding-top: propValue(1280, $top, $viewport);
    padding-bottom: propValue(1280, $bottom, $viewport);
  }
  @include respond-to('laptop-l') {
    padding-top: propValue(1152, $top, $viewport);
    padding-bottom: propValue(1152, $bottom, $viewport);
  }
  @include respond-to('laptop') {
    padding-top: propValue(1024, $top, $viewport);
    padding-bottom: propValue(1024, $bottom, $viewport);
  }
  @include respond-to('laptop-small') {
    padding-top: propValue(800, $top, $viewport);
    padding-bottom: propValue(800, $bottom, $viewport);
  }
}

@mixin prop-margin($value, $viewport: 1920) {
  @include respond-to('desktop-xl') {
    margin: propValue(1600, $value, $viewport);
  }
  @include respond-to('desktop-large') {
    margin: propValue(1440, $value, $viewport);
  }
  @include respond-to('desktop-l') {
    margin: propValue(1360, $value, $viewport);
  }
  @include respond-to('desktop') {
    margin: propValue(1280, $value, $viewport);
  }
  @include respond-to('laptop-l') {
    margin: propValue(1152, $value, $viewport);
  }
  @include respond-to('laptop') {
    margin: propValue(1024, $value, $viewport);
  }
  @include respond-to('laptop-small') {
    margin: propValue(800, $value, $viewport);
  }
}

@mixin prop-margin-top($value, $viewport: 1920) {
  @include respond-to('desktop-xl') {
    margin-top: propValue(1600, $value, $viewport);
  }
  @include respond-to('desktop-large') {
    margin-top: propValue(1440, $value, $viewport);
  }
  @include respond-to('desktop-l') {
    margin-top: propValue(1360, $value, $viewport);
  }
  @include respond-to('desktop') {
    margin-top: propValue(1280, $value, $viewport);
  }
  @include respond-to('laptop-l') {
    margin-top: propValue(1152, $value, $viewport);
  }
  @include respond-to('laptop') {
    margin-top: propValue(1024, $value, $viewport);
  }
  @include respond-to('laptop-small') {
    margin-top: propValue(800, $value, $viewport);
  }
}

@mixin prop-margin-right($value, $viewport: 1920) {
  @include respond-to('desktop-xl') {
    margin-right: propValue(1600, $value, $viewport);
  }
  @include respond-to('desktop-large') {
    margin-right: propValue(1440, $value, $viewport);
  }
  @include respond-to('desktop-l') {
    margin-right: propValue(1360, $value, $viewport);
  }
  @include respond-to('desktop') {
    margin-right: propValue(1280, $value, $viewport);
  }
  @include respond-to('laptop-l') {
    margin-right: propValue(1152, $value, $viewport);
  }
  @include respond-to('laptop') {
    margin-right: propValue(1024, $value, $viewport);
  }
  @include respond-to('laptop-small') {
    margin-right: propValue(800, $value, $viewport);
  }
}

@mixin prop-margin-bottom($value, $viewport: 1920) {
  @include respond-to('desktop-xl') {
    margin-bottom: propValue(1600, $value, $viewport);
  }
  @include respond-to('desktop-large') {
    margin-bottom: propValue(1440, $value, $viewport);
  }
  @include respond-to('desktop-l') {
    margin-bottom: propValue(1360, $value, $viewport);
  }
  @include respond-to('desktop') {
    margin-bottom: propValue(1280, $value, $viewport);
  }
  @include respond-to('laptop-l') {
    margin-bottom: propValue(1152, $value, $viewport);
  }
  @include respond-to('laptop') {
    margin-bottom: propValue(1024, $value, $viewport);
  }
  @include respond-to('laptop-small') {
    margin-bottom: propValue(800, $value, $viewport);
  }
}

@mixin prop-margin-left($value, $viewport: 1920) {
  @include respond-to('desktop-xl') {
    margin-left: propValue(1600, $value, $viewport);
  }
  @include respond-to('desktop-large') {
    margin-left: propValue(1440, $value, $viewport);
  }
  @include respond-to('desktop-l') {
    margin-left: propValue(1360, $value, $viewport);
  }
  @include respond-to('desktop') {
    margin-left: propValue(1280, $value, $viewport);
  }
  @include respond-to('laptop-l') {
    margin-left: propValue(1152, $value, $viewport);
  }
  @include respond-to('laptop') {
    margin-left: propValue(1024, $value, $viewport);
  }
  @include respond-to('laptop-small') {
    margin-left: propValue(800, $value, $viewport);
  }
}

@mixin prop-margin-inline($left, $right, $viewport: 1920) {
  @include respond-to('desktop-xl') {
    margin-left: propValue(1600, $left, $viewport);
    margin-right: propValue(1600, $right, $viewport);
  }
  @include respond-to('desktop-large') {
    margin-left: propValue(1440, $left, $viewport);
    margin-right: propValue(1440, $right, $viewport);
  }
  @include respond-to('desktop-l') {
    margin-left: propValue(1360, $left, $viewport);
    margin-right: propValue(1360, $right, $viewport);
  }
  @include respond-to('desktop') {
    margin-left: propValue(1280, $left, $viewport);
    margin-right: propValue(1280, $right, $viewport);
  }
  @include respond-to('laptop-l') {
    margin-left: propValue(1152, $left, $viewport);
    margin-right: propValue(1152, $right, $viewport);
  }
  @include respond-to('laptop') {
    margin-left: propValue(1024, $left, $viewport);
    margin-right: propValue(1024, $right, $viewport);
  }
  @include respond-to('laptop-small') {
    margin-left: propValue(800, $left, $viewport);
    margin-right: propValue(800, $right, $viewport);
  }
}

@mixin prop-margin-vertical($top, $bottom, $viewport: 1920) {
  @include respond-to('desktop-xl') {
    margin-top: propValue(1600, $top, $viewport);
    margin-bottom: propValue(1600, $bottom, $viewport);
  }
  @include respond-to('desktop-large') {
    margin-top: propValue(1440, $top, $viewport);
    margin-bottom: propValue(1440, $bottom, $viewport);
  }
  @include respond-to('desktop-l') {
    margin-top: propValue(1360, $top, $viewport);
    margin-bottom: propValue(1360, $bottom, $viewport);
  }
  @include respond-to('desktop') {
    margin-top: propValue(1280, $top, $viewport);
    margin-bottom: propValue(1280, $bottom, $viewport);
  }
  @include respond-to('laptop-l') {
    margin-top: propValue(1152, $top, $viewport);
    margin-bottom: propValue(1152, $bottom, $viewport);
  }
  @include respond-to('laptop') {
    margin-top: propValue(1024, $top, $viewport);
    margin-bottom: propValue(1024, $bottom, $viewport);
  }
  @include respond-to('laptop-small') {
    margin-top: propValue(800, $top, $viewport);
    margin-bottom: propValue(800, $bottom, $viewport);
  }
}

@mixin prop-gap($original, $viewport: 1920) {
  @include respond-to('desktop-xl') {
    gap: propValue(1600, $original, $viewport);
  }
  @include respond-to('desktop-large') {
    gap: propValue(1440, $original, $viewport);
  }
  @include respond-to('desktop-l') {
    gap: propValue(1360, $original, $viewport);
  }
  @include respond-to('desktop') {
    gap: propValue(1280, $original, $viewport);
  }
  @include respond-to('laptop-l') {
    gap: propValue(1152, $original, $viewport);
  }
  @include respond-to('laptop') {
    gap: propValue(1024, $original, $viewport);
  }
  @include respond-to('laptop-small') {
    gap: propValue(800, $original, $viewport);
  }
}

@mixin prop-content-height($viewport: 1920) {
  height: calc(100vh - 134px - 53px);
  @include respond-to('desktop-xl') {
    height: calc(100vh - (134px * 1600 / 1920) - (53px * 1600 / 1920));
  }
  @include respond-to('desktop-large') {
    height: calc(100vh - (134px * 1440 / 1920) - (53px * 1440 / 1920));
  }
  @include respond-to('desktop-l') {
    height: calc(100vh - (134px * 1360 / 1920) - (53px * 1360 / 1920));
  }
  @include respond-to('desktop') {
    height: calc(100vh - (134px * 1280 / 1920) - (53px * 1280 / 1920));
  }
  @include respond-to('laptop-l') {
    height: calc(100vh - (134px * 1152 / 1920) - (53px * 1152 / 1920));
  }
  @include respond-to('laptop') {
    height: calc(100vh - (134px * 1024 / 1920) - (53px * 1024 / 1920));
  }
  @include respond-to('laptop-small') {
    height: calc(100vh - (134px * 600 / 1920) - (53px * 600 / 1920));
  }
}

@mixin prop-grid-template-rows($fractions, $original, $viewport: 1920) {
  @include respond-to('desktop-xl') {
    grid-template-rows: repeat(
      $fractions,
      propValue(1600, $original, $viewport)
    );
  }
  @include respond-to('desktop-large') {
    grid-template-rows: repeat(
      $fractions,
      propValue(1440, $original, $viewport)
    );
  }
  @include respond-to('desktop-l') {
    grid-template-rows: repeat(
      $fractions,
      propValue(1360, $original, $viewport)
    );
  }
  @include respond-to('desktop') {
    grid-template-rows: repeat(
      $fractions,
      propValue(1280, $original, $viewport)
    );
  }
  @include respond-to('laptop-l') {
    grid-template-rows: repeat(
      $fractions,
      propValue(1152, $original, $viewport)
    );
  }
  @include respond-to('laptop') {
    grid-template-rows: repeat(
      $fractions,
      propValue(1024, $original, $viewport)
    );
  }
  @include respond-to('laptop-small') {
    grid-template-rows: repeat(
      $fractions,
      propValue(800, $original, $viewport)
    );
  }
}

@mixin prop-grid-template-columns($fractions, $original, $viewport: 1920) {
  @include respond-to('desktop-xl') {
    grid-template-columns: repeat(
      $fractions,
      propValue(1600, $original, $viewport)
    );
  }
  @include respond-to('desktop-large') {
    grid-template-columns: repeat(
      $fractions,
      propValue(1440, $original, $viewport)
    );
  }
  @include respond-to('desktop-l') {
    grid-template-columns: repeat(
      $fractions,
      propValue(1360, $original, $viewport)
    );
  }
  @include respond-to('desktop') {
    grid-template-columns: repeat(
      $fractions,
      propValue(1280, $original, $viewport)
    );
  }
  @include respond-to('laptop-l') {
    grid-template-columns: repeat(
      $fractions,
      propValue(1152, $original, $viewport)
    );
  }
  @include respond-to('laptop') {
    grid-template-columns: repeat(
      $fractions,
      propValue(1024, $original, $viewport)
    );
  }
  @include respond-to('laptop-small') {
    grid-template-columns: repeat(
      $fractions,
      propValue(800, $original, $viewport)
    );
  }
}

@mixin prop-top($original, $viewport: 1920) {
  @include respond-to('desktop-xl') {
    top: propValue(1600, $original, $viewport);
  }
  @include respond-to('desktop-large') {
    top: propValue(1440, $original, $viewport);
  }
  @include respond-to('desktop-l') {
    top: propValue(1360, $original, $viewport);
  }
  @include respond-to('desktop') {
    top: propValue(1280, $original, $viewport);
  }
  @include respond-to('laptop-l') {
    top: propValue(1152, $original, $viewport);
  }
  @include respond-to('laptop') {
    top: propValue(1024, $original, $viewport);
  }
  @include respond-to('laptop-small') {
    top: propValue(800, $original, $viewport);
  }
}

@mixin prop-right($original, $viewport: 1920) {
  @include respond-to('desktop-xl') {
    right: propValue(1600, $original, $viewport);
  }
  @include respond-to('desktop-large') {
    right: propValue(1440, $original, $viewport);
  }
  @include respond-to('desktop-l') {
    right: propValue(1360, $original, $viewport);
  }
  @include respond-to('desktop') {
    right: propValue(1280, $original, $viewport);
  }
  @include respond-to('laptop-l') {
    right: propValue(1152, $original, $viewport);
  }
  @include respond-to('laptop') {
    right: propValue(1024, $original, $viewport);
  }
  @include respond-to('laptop-small') {
    right: propValue(800, $original, $viewport);
  }
}

@mixin prop-bottom($original, $viewport: 1920) {
  @include respond-to('desktop-xl') {
    bottom: propValue(1600, $original, $viewport);
  }
  @include respond-to('desktop-large') {
    bottom: propValue(1440, $original, $viewport);
  }
  @include respond-to('desktop-l') {
    bottom: propValue(1360, $original, $viewport);
  }
  @include respond-to('desktop') {
    bottom: propValue(1280, $original, $viewport);
  }
  @include respond-to('laptop-l') {
    bottom: propValue(1152, $original, $viewport);
  }
  @include respond-to('laptop') {
    bottom: propValue(1024, $original, $viewport);
  }
  @include respond-to('laptop-small') {
    bottom: propValue(800, $original, $viewport);
  }
}

@mixin prop-left($original, $viewport: 1920) {
  @include respond-to('desktop-xl') {
    left: propValue(1600, $original, $viewport);
  }
  @include respond-to('desktop-large') {
    left: propValue(1440, $original, $viewport);
  }
  @include respond-to('desktop-l') {
    left: propValue(1360, $original, $viewport);
  }
  @include respond-to('desktop') {
    left: propValue(1280, $original, $viewport);
  }
  @include respond-to('laptop-l') {
    left: propValue(1152, $original, $viewport);
  }
  @include respond-to('laptop') {
    left: propValue(1024, $original, $viewport);
  }
  @include respond-to('laptop-small') {
    left: propValue(800, $original, $viewport);
  }
}
