@import '../../styles/_vars.scss';
@import '../../styles/mixins';
.custom-button {
  &.primary {
    background-color: $mainColor;
    color: #fff;
    svg {
      path {
        fill: #fff;
      }
    }
  }
  &.light {
    background-color: #fff;
    color: $mainColor;
    box-shadow: 0px 0px 2px -1px $light-grey;
    svg {
      path {
        fill: $mainColor;
      }
    }
  }
  background-color: $mainColor;
  color: #fff;
  width: 100%;
  height: 65px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 700;
  transition: 0.3s;
  outline: none;
  box-shadow: none;
  border: none;
  cursor: pointer;
  span {
    &::first-letter {
      text-transform: capitalize;
    }
  }
  svg {
    width: 24px;
    height: 24px;
    margin-left: 15px;
  }
  @include respond-to('desktop') {
    height: 60px;
    font-size: 18px;
  }
  @include respond-to('laptop') {
    height: 56px;
    font-size: 17px;
  }
  @include respond-to('tablet') {
    height: 50px;
    font-size: 15px;
  }
  @include respond-to('mobile') {
    width: 100%;
    font-size: 14px;
  }
  &:hover {
    opacity: 0.9;
  }
  &:disabled {
    opacity: 0.4;
  }
}
