@import 'src/styles/_vars.scss';

.ski-animation {
  // position: fixed;
  // bottom: 0;
  //pointer-events: none;
  position: relative;
  height: $footer-height;
  z-index: -1;

  svg {
    position: absolute;
    bottom: 0;
    max-width: 100%;
  }

  #chairLift {
    z-index: 2;
    top: 10px;
    animation: lift 10s 0s infinite cubic-bezier(0.88, 0.77, 1, 0.55);
    transform: translate(-20%, 12%);
    /* santa transform: scale(.5) translate(200%, 20%);;
    z-index: 2;
    top: 40px;
    animation: lift 10s 0s infinite cubic-bezier(0.21, 0.34, 0.88, 0.68); */
    /* halloween animation: lift 10s 0s infinite cubic-bezier(.88, .77,1, .55);
    transform: translate(0, 73%);*/
  }

  @keyframes lift {
    from {
      // santa transform: scale(.5) translate(-20%, 160%);
      transform: translate(-20%, 14%);
      // halloween transform: translate(-40%, 106%)
    }
    to {
      // santa transform: scale(.5) translate(200%, 0%);
      transform: translate(100%, -72%);
      // halloween transform: translate(150%, -40%);
    }
  }
}
