@import 'src/styles/vars';
.burger-button {
  display: none;
  @include mobileMediaQuery() {
    display: block;
    background: transparent;
    border: none;
    height: 60px;
    width: 60px;
    padding: 0;

    svg {
      max-width: 50px;
    }

    .cross-line {
      height: 3px;
      border-radius: 2px;
      background: black;
      width: 40px;
      display: block;
      transform: rotate(-45deg);
      position: absolute;
      top: 0;
      &:first-child {
        transform: rotate(45deg);
      }
    }
  }
}
