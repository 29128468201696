@import '../../styles/vars';
@import '../../styles/mixins';

.app-header-default {
  height: $header-height;
  position: fixed;
  top: 0;
  width: 100vw;
  left: 0;
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 calc(#{$header-height} / 2);
  padding-left: 13.5vh;
  @include respond-to('desktop') {
    padding-left: 9vw;
  }
  @include respond-to('laptop') {
    padding-left: 3vw;
  }
  @include respond-to('tablet') {
    padding-left: 6vw;
  }
  @include respond-to('mobile') {
    padding-left: 3vw;
  }
  z-index: $zIndexHeader;
  .burger-button {
    svg rect {
      fill: $darkBlue;
    }
  }

  &.is-transparent {
    background: transparent;

    .burger-button {
      svg rect {
        fill: white;
      }
    }
    .logo {
      path {
        fill: white;
      }
    }

    nav ul li a {
      color: white;

      &.isActive {
        &::after {
          background: white;
        }
      }
    }
  }

  .logo {
    max-width: 150px;

    &,
    path {
      fill: $darkBlue;
    }
  }

  nav {
    display: flex;
    align-items: center;
    justify-content: space-around;

    ul {
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: space-around;

      li {
        list-style: none;

        a {
          color: $darkBlue;
          text-decoration: none;
          font-family: 'Inter', sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 26px;
          text-transform: capitalize;
          margin: 0 1vw;

          &.isActive {
            position: relative;
            &::after {
              content: '';
              height: 4px;
              background: $darkBlue;
              width: 30px;
              position: absolute;
              left: 50%;
              bottom: -10px;
              border-radius: 2px;
              transform: translateX(-50%);
            }
          }
        }
      }
    }
  }

  @include mobileMediaQuery() {
    svg {
      width: 30vw;
      max-width: 150px;
    }
    nav,
    &.is-transparent nav {
      position: fixed;
      top: 0;
      height: 100vh;
      width: 100vw;
      background: white;
      left: 100vw;
      transition: left 0.3s ease-in;

      ul {
        display: flex;
        flex-direction: column;

        li {
          height: 10vh;
          a {
            color: black;
          }
        }
      }

      .burger-button {
        position: absolute;
        left: calc(100vw - 80px);
        top: 35px;

        #cross-svg {
          path {
            fill: black;
          }
        }
      }

      &.is-open {
        left: 0;
        transition: left 0.3s ease-in;
      }
    }
  }
}

.lang-selector {
  position: relative;

  button {
    border: none;
    outline: none;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $darkBlue;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    padding: 1px 6px;
    text-transform: capitalize;
    .is-transparent & {
      color: white;
      @include mobileMediaQuery() {
        color: black;
        &.selector {
          display: none;
        }
      }
    }

    @include mobileMediaQuery() {
      color: black;
      &.selector {
        display: none;
      }
    }

    svg {
      width: 20px;
      margin-right: 5px;
    }
  }

  .others {
    display: none;
    position: absolute;

    .hidden {
      display: none;
    }

    @include mobileMediaQuery() {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      position: relative;
      .app-header-default.is-transparent & {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        position: relative;

        li {
          color: black;
        }
      }

      li {
        height: initial;
        .app-header-default.is-transparent & {
          height: initial;
        }
        position: relative;
      }

      .hidden {
        display: block;

        &::after {
          content: '';
          display: block;
          position: absolute;
          background: $darkBlue;
          height: 4px;
          width: 30px;
          left: 50%;
          bottom: -10px;
          border-radius: 2px;
          transform: translateX(-50%);
        }
      }
    }
  }

  &.open {
    .others {
      display: block;
    }
  }
}
