@import '../../../styles/_vars.scss';
@import '../../../styles/_mixins.scss';

.auth {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .error {
    margin: 0;
    color: #772d25;
  }

  .login-container {
    display: flex;
    flex-direction: column;
    z-index: 100;
    .login-form {
    }

    .login-title {
    }

    .login-input-container {
      display: flex;
      flex-direction: column;
      input {
        border-radius: 4px;
        font-size: 20px;
        margin: 5px 0;
        padding: 5px;
      }
    }

    .login-button {
      height: max-content;
      padding: 10px;
    }
  }

  .ski-animation {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 1;
  }
}
