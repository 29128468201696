$lightBlue: #05e5e5;
$darkBlue: #139eee;
$mainColor: #139eee;
$main-background: linear-gradient(
  180deg,
  $lightBlue -33.4%,
  rgba($darkBlue, 0.9) 90.97%
);
$light-grey: #f7f7f9;
$slate-blue: #175c83;
$deep-blue: #1e4e75;

$header-height: 90px;
$footer-height: 260px;
$footer-container-height: 485px;
$footer-container-background: linear-gradient(
  89.91deg,
  #2875b4 14.79%,
  #63dee3 120.86%
);
$text-color: #ffffff;
$text-black-color: #112e3f;
$text-blue: #1f709d;
$text-dark-blue: #1e4e75;
$grey: #f8f8f8;
$dark-grey: #aab2c5;
$tree-green: #38512e;

$zIndexHeader: 10;

$width-mobile-threshold: 768px;
$height-mobile-threshold: 600px;

@mixin mobileMediaQuery {
  @media screen and (max-width: $width-mobile-threshold),
    screen and (max-height: $height-mobile-threshold) {
    @content;
  }
}
