@import '../../../styles/_mixins.scss';
@import '../../../styles/_vars.scss';

.fundraising-container {
  background-color: #f9f9f9;
  padding-top: 8vh;

  .main-text {
    font-weight: 700;
    font-size: 40px;
    margin-top: 10px;
    @include prop-fontSize(40px);

    color: #ffffff;
  }

  .content {
    margin-top: 4vh;
    display: flex;
    align-items: center;
    flex-direction: column;

    .image-container {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 53%;
      }
    }

    .introduction-container {
      padding: 5vh 8vw;
    }

    .items-container {
      width: 100%;
      .items-header {
        display: flex;
        align-items: center;
        width: 100%;
        flex-direction: column;
        .items-title {
          margin-top: 20px;
          font-weight: 700;
          font-size: 40px;
          @include prop-fontSize(40px);

          text-align: justify;
        }

        .dropdown {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-bottom: 40px;
          label {
            font-weight: 700;
            font-size: 25px;
            @include prop-fontSize(25px);
            line-height: 30px;
            text-align: justify;
            margin-right: 40px;
          }

          select {
            font-weight: 700;
            font-size: 25px;
            @include prop-fontSize(25px);
            text-align: justify;

            color: #041524;
            position: relative;
            background-color: #dce5ec;
            border: none;
            box-shadow: 0px 12px 24px rgba(222, 222, 222, 0.199465);
            border-radius: 12px;
            appearance: none;
            padding: 8px;
            padding-right: 25px;
            background: #dce5ec
              url("data:image/svg+xml,%3Csvg width='16' height='15' viewBox='0 0 16 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cline y1='-1' x2='14.9499' y2='-1' transform='matrix(0.493867 0.869537 -0.642049 0.766663 0 2)' stroke='%23139EEE' stroke-width='2'/%3E%3Cline y1='-1' x2='14.9502' y2='-1' transform='matrix(0.493835 -0.869556 0.642082 0.766636 7.91211 15)' stroke='%23139EEE' stroke-width='2'/%3E%3C/svg%3E");
            background-position: right 5px top 50%;
            background-repeat: no-repeat;
          }
        }
      }

      .no-results {
        min-height: 200px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        p {
          font-weight: 700;
          font-size: 25px;
          @include prop-fontSize(25px);
          line-height: 30px;
          text-align: justify;

          color: $text-black-color;
        }
      }

      .items-list {
        padding: 0 8.3333vw;
        .items-department {
          margin-top: 40px;
          font-weight: 700;
          font-size: 40px;
          @include prop-fontSize(40px);
          line-height: 48px;
          text-align: justify;

          color: #ffffff;
        }

        .items-cards {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          @include respond-to('tablet') {
            grid-template-columns: repeat(2, 1fr);
          }
          @include respond-to('mobile') {
            grid-template-columns: repeat(1, 1fr);
          }
          grid-gap: 20px;
          flex-wrap: wrap;
          justify-content: flex-start;
          flex-direction: row;
          align-items: center;
          .items-card {
            z-index: 5;
            position: relative;
            cursor: pointer;
            //add glow on hover
            &:hover {
              box-shadow: 0px 4px 10px rgba(19, 158, 238, 0.09);
            }
            display: flex;
            flex: 1;
            flex-direction: column;
            width: 100%;
            height: 100%;
            background-color: white;
            overflow: hidden;
            border-radius: 12px;
            padding-bottom: 50px;
            .card-title {
              margin: 0.1em 15px;
              font-weight: 700;
              font-size: 25px;
              line-height: 30px;
              text-align: center;
              @include prop-fontSize(25px);
            }

            .divider {
              width: 100%;
              background-color: #f7f7f9;
              min-height: 3px;
              margin-bottom: 10px;
            }

            .card-description {
              font-weight: 500;
              margin-left: 15px;
              margin-right: 15px;
              font-size: 20px;
              line-height: 24px;

              @include prop-fontSize(20px);
              color: rgba(4, 21, 36, 0.6);
            }

            .card-button {
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              position: absolute;
              border-radius: 6px;
              bottom: 12px;
              right: 12px;
              background-color: $mainColor;
              width: 210px;
              height: 48px;
              font-weight: 700;
              font-size: 25px;
              line-height: 30px;
              text-align: center;

              color: #ffffff;
              @include prop-height(48px);
              @include prop-width(210px);
              @include prop-fontSize(25px);
            }
          }

          .restricted {
            opacity: 0.5;
            cursor: not-allowed;
          }
        }
      }
    }

    .about-team-container {
      padding: 5vh 8vw;
    }
  }

  .ski-animation {
    z-index: 1;
    margin-top: 140px;
  }
}

.modal {
  .modal-title {
    font-weight: 700;
    font-size: 40px;
    text-align: center;

    color: #041524;
  }

  a {
    font-size: 20px;
    text-decoration: underline;
    cursor: pointer;
    margin-top: 20px;
  }

  hr {
    border: 2px solid $grey;
  }

  li {
    margin-top: 20px;
  }

  .download-list {
    margin-top: 20px;

    p {
      font-size: 20px;
    }
  }

  .private-file {
    color: grey;
  }
}
